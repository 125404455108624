<template>
  <swiper-slide
    :style="{height: `${slide_height}`}"
    ref="wrapper"
    class="cp un_sel"
  >
    <div class="views_shopping_mail_modules_swiper_featured_slide bbox">
      <img
        :src="`${$img_url}${data.pic}`"
        alt=""
        class="img"
      >
      <div class="tag"
        v-if="tag && tag_name"
      >{{tag_name}}</div>
    </div>
  </swiper-slide>
</template>

<script>
import { SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    SwiperSlide,
  },
  props:{
    data: {
      type: Object,
      default: undefined,
    },
    height: {
      type: Number,
      default: undefined,
    },
    tag: {
      type: Boolean,
      default: true,
    }
  },
  data(){
    return {
      calc_height: undefined,
    }
  },
  computed: {
    tag_name(){
      const {data} = this;
      if(!data){
        return false;
      }
      const {type} = data;
      if(type === 1){
        return '任务模板';
      }
      if(type === 2){
        return '教学包';
      }
      if(type === 3){
        return '活动';
      }
      return false;
    },
    slide_height(){
      const {height, calc_height} = this;
      if(height || calc_height){
        return `${height || calc_height}px`
      }
      return '100%';
    },
  },
  mounted(){
    this.$nextTick(function(){
      this.init();
    })
  },
  methods: {
    init(){
      this.initSize();
    },
    initSize(){
      const {height} = this;
      const {clientWidth} = this.$refs.wrapper.$el
      if(height === undefined){
        const calc_height = this.calculativeHeight({width: clientWidth});
        this.calc_height = calc_height;
      }
    },
    /**
     * 按照16:9转换高度
     */
    calculativeHeight(params={}){
      const {width} = params;
      const height = (9 * width) / 16;
      return height;
    },
  },
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_swiper_featured_slide{
  position: relative;
  height: 100%;
  .img{
    width: 100%;
    height: 100%;
    display: block;
    position: inherit;
    z-index: 2;
    border-radius: 10px;
  }
  .tag{
    position: absolute;
    z-index: 3;
    color: #fff;
    background-color: #000;
    border-radius: 15px 0 10px 0;
    line-height: 30px;
    padding: 0 14px;
    bottom: 0;
    right: 0;
  }
}
</style>
